<!-- 低压母线 -->
<template>
  <div id="low-busbar">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>越限事件-低压母线</span>
      </div>
      <div id="chart"></div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="formData">
          <el-form-item label="活动名称：">
            <el-input style="width: 176px" v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="开始时间：">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="formData.date1"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="formData.date1"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="仪表名称：">
            <el-input style="width: 132px" v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="参数名称：">
            <el-input style="width: 132px" v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="类型：">
            <el-select v-model="formData.region">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" icon="el-icon-download">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table class="table" :data="tableData">
        <el-table-column align="center" prop="id" label="序号">
        </el-table-column>
        <el-table-column align="center" prop="name" label="设备名称">
        </el-table-column>
        <el-table-column align="center" prop="zero" label="Ua">
        </el-table-column>
        <el-table-column align="center" prop="one" label="参数值">
        </el-table-column>
        <el-table-column align="center" prop="two" label="Ub">
        </el-table-column>
        <el-table-column align="center" prop="three" label="参数值">
        </el-table-column>
        <el-table-column align="center" prop="four" label="Uc">
        </el-table-column>
        <el-table-column align="center" prop="six" label="参数值">
        </el-table-column>
        <el-table-column align="center" prop="seven" label="限定值" width="200">
        </el-table-column>
        <el-table-column align="center" prop="time" label="时间" width="175">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" @click="viewDialog = true">查看</el-button>
              <span>|</span>
              <el-button type="text" @click="deleteDialog = true">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <button class="home-page">首页</button>
        <el-pagination
          :current-page="requestParam.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          layout="prev, pager, next, sizes,slot"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          @size-change="changeSize"
          @current-change="changeCurrent"
        >
        </el-pagination>
        <button class="tail-page">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input
            size="mini"
            class="pagination-input"
          ></el-input>
          <span class="text">页</span>
          <button class="confirm">确定</button>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="viewDialog" width="751px">
      <el-form class="details-form" ref="form" :inline="true" :model="formData">
        <el-form-item label="设备名称">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="设备编号">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="设备位置">
          <el-select v-model="formData.region">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备状态">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="报警类型">
          <el-select v-model="formData.region">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上阙值">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="下阙值">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="A相">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="viewDialog = false">取 消</el-button>
        <el-button type="primary" @click="viewDialog = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteDialog" width="436px">
      <div class="delete">确定要删除该分类吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteDialog = false">取 消</el-button>
        <el-button type="primary" @click="deleteDialog = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { eventApi } from "@/api/event.js";
import * as echarts from "echarts";
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
      },
      exportDialog: false,
      tableData: [
        {
          id: 1,
          name: "仪表名称",
          zero: "越线",
          one: "95.5",
          two: "越线",
          three: "95.5",
          four: "越线",
          six: "95.5",
          seven:'上阈值 : 176 下阈值 : 264',
          time:'2023-09-20 11:02:23'
        },
      ],
      select: 0,
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      viewDialog: false,
      deleteDialog: false,
    };
  },

  mounted() {
    this.drawChart();
  },

  methods: {
    switchTabs(index) {
      this.select = index;
    },
    drawChart() {
      var chart = echarts.init(document.getElementById("chart"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "低压母线情况",
          textStyle: {
            fontSize: 20,
            color: "#e8f4ff",
          },
          left: "center",
        },
        tooltip: {},
        legend: {
          show: true,
          right: "1%",
          icon: "circle",
          textStyle: {
            color: "#aed6ff",
          },
        },
        color: ["#ffc03b", "#3376f7", "#14bf9b"],
        xAxis: {
          data: [
            "08:00",
            "08:15",
            "08:30",
            "08:45",
            "09:00",
            "09:15",
            "09:30",
            "09:45",
            "10:00",
            "10:15",
            "10:30",
            "10:45",
            "11:00",
            "11:15",
            "11:30",
            "11:45",
            "12:00",
            "12:15",
            "12:30",
            "12:45",
            "13:00",
            "13:15",
            "13:30",
            "13:45",
            "14:00",
            "14:15",
            "14:30",
            "14:45",
            "15:00",
            "15:15",
            "15:30",
            "15:45",
            "16:00",
            "16:15",
            "16:30",
            "16:45",
            "17:00",
            "17:15",
            "17:30",
            "17:45",
            "18:00",
          ],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 12,
            },
          },
        },
        yAxis: {
          name: "单位:v",
          nameTextStyle: {
            color: "#aed6ff",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "Ua",
            type: "line",
            data: [10, 22, 24, 75, 25, 14, 47],
            symbol: "none",
          },
          {
            name: "Ub",
            type: "line",
            data: [10, 52, 75, 25, 78, 65, 78],
            symbol: "none",
          },
          {
            name: "Uc",
            type: "line",
            data: [10, 52, 65, 85, 32, 42, 75],
            symbol: "none",
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option);
    },
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#low-busbar {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 24px 18px 30px 36px;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
/* 查询表单 */
.query-form {
  width: 1570px;
  height: 62px;
  border-radius: 3px;
  background-color: rgba(6, 29, 74, 0.79);
  padding-left: 18px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.query-form :deep() .el-form {
  height: 40px;
}
.form :deep() .el-icon-date {
  color: #aed6ff;
}
.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}
.form :deep() .el-form-item {
  margin: 0 8px 0 0;
}
/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #041c4c;
  border: 1px solid #09295b;
  color: #aed6ff;
}
.form :deep() .el-date-editor {
  width: 155px;
  padding-right: 0;
}
.form :deep() .el-select .el-input .el-input__inner {
  width: 132px;
  padding: 0 25px 0 8px;
}
/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}
#chart {
  width: 1570px;
  height: 315px;
}
/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}
/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination :deep() .el-pagination {
  padding-left: 0;
}
.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}
.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination >>> .btn-prev,
.el-pagination >>> .btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.el-pagination >>> .el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}
.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  color: #92b5da;
  margin: 0 10px;
}
.pagination-input >>> .el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
}
.pagination :deep().el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}
.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}
.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}
.operate span {
  margin: 0 3px;
}
/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}
::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}
::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__footer{
 padding-top: 0;
}
.details-form {
  width: 688px;
  padding: 0 40px 0 30px;
  margin: 41px 32px 0 31px;
  box-sizing: border-box;
}
.details-form :deep().el-form-item__label {
  width: 60px;
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
}
.details-form :deep() .el-input .el-input__inner {
  width: 539px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}
.delete {
  font-size: 16px;
  font-weight: 700;
  color: #c9d4f1;
  margin: 42px 0 64px 39px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}
.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}
</style>
